.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  background: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.left, .right {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.left img {
  width: 100%;
  height: auto;
}

.input-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.textInput {
  padding: 10px;
  font-size: 16px;
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
}

.submitButton {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submitButton:hover {
  background-color: #0056b3;
}

.apiKeyInput, .dropdown, .aiMessage {
  margin-top: 20px;
  margin-left: 80px;
  padding: 10px;
  font-size: 16px;
  width: 80%;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-width: 80%; /* 設定最大寬度 */
  box-sizing: border-box; /* 確保 padding 不會影響寬度 */
}

.aiMessage {
  margin-top: 20px;
  margin-left: 20px;
  padding: 10px;
  font-size: 16px;
  width: 80%;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-width: 80%; /* 設定最大寬度 */
  box-sizing: border-box; /* 確保 padding 不會影響寬度 */
}

.aiMessage {
  background-color: #f0f0f0;
  word-wrap: break-word;
  overflow: auto;
}

.image-columns {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  max-width: 80%; /* 設定最大寬度 */
}

.column {
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 0 10px; /* 增加左右間距 */
}

.column img {
  max-width: 100%;
  height: auto;
  margin: 0 10px; /* 增加圖片間的間距 */
}

.full-width-image {
  width: 80%;
  margin-left: 20px;
  margin-top: 20px;
}

.full-width-image img {
  width: 80%;
  height: auto;
}